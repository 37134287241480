import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { environment } from '@env';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsModule } from '@ngxs/store';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { AppComponent } from './app.component';
import { AppEnterpriseModule } from './app-enterprise.module';
import { AppMainModule } from './app-main.module';
import { AppProxyModule } from './app-proxy.module';
import { AppLightHubModule } from './app-light-hub.module';
import { TagInputModule } from 'ngx-chips';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    CoreModule,
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    }),
    NoopAnimationsModule,
    TagInputModule,
    environment.plugins,
    NgSelectModule,
    /* eslint-disable no-nested-ternary */
    environment.enterprise
      ? AppEnterpriseModule
      : environment.proxy
        ? AppProxyModule
        : environment.light
          ? AppLightHubModule
          : AppMainModule, // TODO: find better way
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
